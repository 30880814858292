"use strict";
let menuBtns = document.querySelectorAll(".menu__btn");
let menuDiv = document.querySelector(".menu__div");
let menuEls = document.querySelectorAll(".menu__el");

for(let menuBtn of menuBtns){
    menuBtn.addEventListener("click", (e) =>{
        if(e.currentTarget.classList.contains("menu__btn--open")){
            menuDiv.classList.remove("menu__div--closed");
        }else if(e.currentTarget.classList.contains("menu__btn--close")){
            menuDiv.classList.toggle("menu__div--closed");
        }
    });
}
for (let menuEl of menuEls) {
    menuEl.addEventListener("click", (e) => {
        menuDiv.classList.remove("menu__div--closed");
    });
};


function konamiCallback(){
    
    document.body.classList.add("konami");
    
    
    var avatarElement = document.querySelector(".intro__image");
    avatarElement.classList.add("image--konami");

    
    avatarElement.setAttribute("src", "assets/images/avatar_konami.jpg");
    avatarElement.setAttribute("srcset", "assets/images/avatar_konami.jpg 1x, images/avatar_konami@2x.jpg 2x");

}

var easter_egg = new Konami(konamiCallback);
